const convertDateToString = (data) => {
    const months = {
        1: "stycznia",
        2: "lutego",
        3: "marca",
        4: "kwietnia",
        5: "maja",
        6: "czerwca",
        7: "lipca",
        8: "sierpnia",
        9: "września",
        10: "października",
        11: "listopada",
        12: "grudnia",
    };

    const [year, month, day] = data.split("-");

    if (!year || !month || !day) {
        return undefined;
    }

    const handleformatDay = (day) => {
        if(day.substr(0,1) === '0'){
            return day.substr(1,1)
        }  
        else {
            return day
        }
    }

    const date = `${handleformatDay(day)} ${months[month]} ${year} r.`;
    console.log(date)
    return date;
};



const convertToHourFormat = (time) => {
    const [hour, minutes] = time.split(":");

    const timeToLocale = `${hour}:${minutes}`;

    return timeToLocale;
};

const convertToLocalDate = ({ date, time }) => {
    if (!date || !time) {
        return undefined;
    }

    const dateString = convertDateToString(date);

    const timeString = convertToHourFormat(time);

    const finalDate = `${dateString} | godz. ${timeString}`;

    return finalDate;
};

export { convertDateToString, convertToLocalDate, convertToHourFormat };
