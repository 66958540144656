import React from "react"
import styled,{css} from "styled-components"
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle.component"
import {
    flexCenter,
    flexColumn,
    transitionMixin,
} from "../../components/mixins"

const SectionWrapper = styled.section`
    ${flexCenter};
    ${flexColumn};
    width: 100%;
    padding: 50px 30px;
    background-color: ${({ theme }) => theme.colors.white};
`

const LogosContainer = styled.div`
    width: 100%;
    ${flexCenter};
    ${flexColumn};
    color: ${({ theme }) => theme.colors.dark_grey};

    ${({isRow})=>isRow?css`
        flex-direction:row;
    `:""}

    div.partner-wrapper {
        display: flex;
        ${flexColumn};
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin:20px;
    }

    p {
        max-width: 900px;
        line-height: 160%;
        margin: 0 auto;
        font-weight: ${({ theme }) => theme.fw.light};
    }

    a {
        ${flexCenter};
        max-width: 200px;
        max-height: 100px;
        margin: 0 20px 20px;
        ${transitionMixin()};

        img {
            width: 100%;
            height: 100%;
            max-height: 120px;
            object-fit: contain;
        }

        &:hover {
            transform: scale(1.1);
        }
    }

    @media screen and (max-width: 560px) {
        ${flexColumn};

        a {
            margin: 20px auto;
        }
    }
`

const PatronsSection = ({ patrons = [],title="We współpracy z",isRow=false }) => (
    <SectionWrapper>
        <HeaderTitle>
            <h2>{title}</h2>
        </HeaderTitle>

        <LogosContainer isRow={isRow}>
            {patrons.map(({ img, url, id, description }) => (
                <div className="partner-wrapper" key={id}>
                    <a href={url} target="_blank" rel="noreferrer noopener">
                        <img src={img} alt="Partner konferencji" />
                    </a>
                    <p>{description}</p>
                </div>
            ))}
        </LogosContainer>
    </SectionWrapper>
)
export default PatronsSection
