import React from "react";
import styled, { css } from "styled-components";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle.component";
import { flexCenter, flexColumn } from "../../components/mixins";
import WebinarPNG from "../../assets/img/webinar.png";
import Button from "../../components/Button/Button.component";
import { scrollTo } from "../../utils/scrollTo";

const SectionWrapper = styled.section`
    ${flexCenter};
    ${flexColumn};
    text-align: center;
    width: 100%;
    padding: 50px 30px;
    background-color: ${({ theme }) => theme.colors.white};

    @media screen and (max-width: 560px) {
        padding: 30px 15px;
    }
`;

const DateContainer = styled.div`
    ${flexCenter};
    flex-wrap: wrap;
    width: 100%;
`;

const DateBox = styled.div`
    ${flexCenter};
    ${flexColumn};
    min-width: 220px;
    padding: 25px 40px;
    color: ${({ theme }) => theme.colors.light_black};
    background-color: #e3e3e3;
    margin: 15px 5px;
    text-transform: uppercase;

    ${({ inverted }) =>
        inverted
            ? css`
                  color: ${({ theme }) => theme.colors.white};
                  background-color: ${({ theme }) => theme.colors.pink};
              `
            : ""}

    p {
        font-size: ${({ theme }) => theme.fz.M};
        font-weight: ${({ theme }) => theme.fw.bold};
    }

    span {
        font-size: ${({ theme }) => theme.fz.XS};
        font-weight: ${({ theme }) => theme.fw.regular};
        margin-top: 5px;
    }

    @media screen and (max-width: 560px) {
        text-align: center;
        min-width: unset;
        width: 100%;

        p {
            font-size: ${({ theme }) => theme.fz.S};
        }

        span {
            font-size: ${({ theme }) => theme.fz.XXS};
        }
    }
`;

const WebinarImage = styled.img`
    max-width: 100px;
    margin: 15px auto 5px;
    filter: opacity(0.65);
`;

const H3 = styled.h3`
    margin: 15px auto;
`;

const H4 = styled.h4`
    margin: 15px auto;
`;

const P = styled.p`
    margin: 10px auto 0;
    font-size: ${({ theme }) => theme.fz.S};
    color: ${({ theme }) => theme.colors.grey};

    ${({ big }) =>
        big
            ? css`
                  font-size: ${({ theme }) => theme.fz.L};
              `
            : ""}

    ${({ thick }) =>
        thick
            ? css`
                  font-weight: ${({ theme }) => theme.fw.bold};
                  color: ${({ theme }) => theme.colors.dark_grey};
              `
            : ""}
            
    ${({ flex }) =>
        flex
            ? css`
                  ${flexCenter};
              `
            : ""}

    ${({ short }) =>
        short
            ? css`
                  max-width: 1000px;
                  margin-left: auto;
                  margin-right: auto;
              `
            : ""}
            
            ${({ light, theme }) =>
        light
            ? css`
                  font-weight: ${theme.fw.light};
              `
            : ""}

            i {
        font-size: 0.7em;
    }
    
`;

const EventDateSection = ({ date, eventTitle, eventDescription }) => (
    <SectionWrapper id="webinar">
        <HeaderTitle>
            <h2>Szczegóły wydarzenia</h2>
        </HeaderTitle>

        {eventTitle && <H3>{eventTitle}</H3>}
        {eventDescription && (
            <P short light>
                {eventDescription}
            </P>
        )}

        <WebinarImage src={WebinarPNG} alt="Ikona Webinaru" />

        <H4>{date}</H4>

        <P>SZKOLENIE ONLINE</P>
        <P flex big thick>
            <i className="fa fa-certificate" aria-hidden="true"></i>
            &nbsp;BEZPŁATNE&nbsp;
            <i className="fa fa-certificate" aria-hidden="true"></i>
        </P>

        <Button onClick={scrollTo("#formularz")}>ZAREZERWUJ MIEJSCE</Button>
    </SectionWrapper>
);
export default EventDateSection;
