import React from "react";
import styled from "styled-components";
import { flexCenter, flexColumn } from "../mixins";

const Wrapper = styled.div`
    ${flexCenter};
    ${flexColumn};
    width: 100%;
    min-height: 600px;
    background-color: ${({ theme }) => theme.colors.white};

    @media screen and (max-width: 560px) {
        min-height: 300px;
    }
`;

const Title = styled.h2`
    color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.fz.XXL};
`;

const NotFoundComponent = ({ message="Nie znaleziono żadnego webinaru."}) => (
    <Wrapper>
        <Title>{message}</Title>
    </Wrapper>
);
export default NotFoundComponent;
