import React from "react";
import styled, { css } from "styled-components";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle.component";
import { flexCenter, flexColumn } from "../../components/mixins";
import Button from "../../components/Button/Button.component";
import SpeakerJPG from "../../assets/img/speaker.jpg";
import { getAllSizesPNG } from "../../utils/imageUtils";

const SectionWrapper = styled.section`
    ${flexCenter};
    ${flexColumn};
    padding: 50px 30px;
    width: 100%;
    color: ${({ theme }) => theme.colors.grey};

    ${({ inverted }) =>
        inverted
            ? css`
                  background-color: ${({ theme }) => theme.colors.white};
              `
            : ""}
`;

const SpeakerWrapper = styled.div`
    ${flexCenter};
    justify-content: space-between;
    color: ${({ theme }) => theme.colors.dark_grey};
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 30px;

    h4 {
        margin: 0 0 15px;
        font-weight: ${({ theme }) => theme.fw.medium};
    }

    @media screen and (max-width: 1000px) {
        ${flexColumn};
    }
`;

const LeftBox = styled.div`
    margin-right: 100px;

    & > img {
        width: 250px;
        height: 250px;
        margin: 15px auto;
        border-radius: 50%;
        border: 1px solid ${({ theme }) => theme.colors.black};
        object-fit: cover;

        @media screen and (max-width: 560px) {
            width: 180px;
            height: 180px;
        }
    }

    @media screen and (max-width: 1000px) {
        margin-right: 0;
    }
`;

const RightBox = styled.div`
    p {
        line-height: 140%;
        text-align: justify;
    }

    p,
    ul,
    ol {
        font-size: ${({ theme }) => theme.fz.S};
        font-weight: ${({ theme }) => theme.fw.light};

        @media screen and (max-width: 560px) {
            font-size: ${({ theme }) => theme.fz.XXS};
        }
    }

    ul,
    ol {
        padding-left: 40px;

        @media screen and (max-width: 560px) {
            text-align: left;
            padding-left: 20px;
        }
    }

    ul {
        list-style: none;
        list-style-type: none;
    }

    ol li,
    ul li {
        position: relative;
        margin: 20px 0;
    }

    ul li {
        &::before {
            position: absolute;
            top: 0.4em;
            left: 0;
            transform: translateX(-1.2em);
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: ${({ theme }) => theme.colors.grey};
        }
    }
`;

const SpeakerSection = ({ name, title, bio, imageFormats }) => {
    const { small } = getAllSizesPNG(imageFormats);
    let speakerName = null;

    if (name && title){
        speakerName = `${title} - ${name}`
    }else if (name){
        speakerName = name;
    }

    return (
        <SectionWrapper inverted id="prelegent">
            <HeaderTitle>
                <h2>Szkolenie prowadzi</h2>
            </HeaderTitle>

            <SpeakerWrapper>
                <LeftBox>
                    <img src={small} alt="Prelegent" />
                </LeftBox>
                <RightBox>
                    {speakerName && (
                        <h4>
                            {speakerName}
                        </h4>
                    )}

                    <p>{bio}</p>
                </RightBox>
            </SpeakerWrapper>
        </SectionWrapper>
    );
};
export default SpeakerSection;
