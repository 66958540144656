import React,{useContext} from "react"
import styled from "styled-components"
import { flexCenter, flexColumn } from "../mixins"
import SEO from "../SEO/SEO.component"
import HeaderTitle from "../HeaderTitle/HeaderTitle.component"
import Button from "../Button/Button.component"
import ArticleTestPNG from "../../assets/img/article_test.jpg"
import {webinarContext} from "../../providers/webinarTemplateProvider/webinar.provider"
import NotFoundComponent from "../404/404.component"



const VideoWrapper = styled.div`
    ${flexCenter};
    ${flexColumn};
    min-height: 600px;
    height:auto;
    padding:40px 0;
    width: 100%;
`

const VideoBox = styled.iframe`
    display: block;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    min-height: 500px;
    border: none;

    @media screen and (max-width: 1080px) {
        min-height: 400px;
        margin: 20px 0;
    }

    @media screen and (max-width: 560px) {
        width: 100%;
        min-height: 300px;
        margin: 20px 0;
    }

    @media screen and (max-width: 400px) {
        width: 100%;
        min-height: 200px;
        margin: 20px 0;
    }
`

const CenterBox = styled.div`
    ${flexCenter};
    width: 100%;
    margin:0 20px 20px auto;
`

const MaterialsComponent = ({ fileToDownload }) =>
    fileToDownload && (
        <>
            {/* <HeaderTitle>
                <h2>Materiały z nagrania</h2>
            </HeaderTitle> */}

            <CenterBox>
                <Button as={"a"} href={fileToDownload} download="Materiały">
                    Pobierz certyfikat
                </Button>
            </CenterBox>
        </>
    )

const VideoComponent = () => {
    const {data:{vimeo_video_number,materials_link,is_materials}} = useContext(webinarContext)

    if(!is_materials){
        return <NotFoundComponent message="Nie odnaleziono podanego źródła."/>
    }
    

    return (
        <SEO title="Webinar - Nagranie" description="Strona nagrania webinaru">
            {vimeo_video_number ? (
                <>
                
                <VideoWrapper>
                <HeaderTitle>
                <h2>Nagranie webinaru</h2>
                
            </HeaderTitle>
            {materials_link && <MaterialsComponent fileToDownload={materials_link} />}
                    <VideoBox
                        src={`https://player.vimeo.com/video/${vimeo_video_number}`}
                        width="1920"
                        height="1080"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                        title="TEST - to jest webinar do testowania streamingu"
                    />

                </VideoWrapper>
                {/* {materials_link &&  <a style={{color:'white',padding:'20px',fontSize:"20px",borderRadius:"30px",backgroundColor:"#31cb4b"}} href={materials_link} target="_blank">Pobierz certyfikat</a>} */}
               
            </>
            ) : <NotFoundComponent message="Nie odnaleziono źródła."/>}
        </SEO>
    )
}

export default VideoComponent
