import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { flexCenter } from "../../components/mixins";
import { getOriginalSizePNG } from "../../utils/imageUtils";
import HeaderPNG from "./../../assets/img/header.png";

const HeaderWrapper = styled.header`
    width: 100%;
    max-height: 800px;

    img {
        ${flexCenter};
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    img.desktop {
        display: none;

        @media screen and (min-width: 650px) {
            ${flexCenter};
        }
    }

    img.mobile {
        ${flexCenter};
        max-height: 500px;

        @media screen and (min-width: 650px) {
            display: none;
        }
    }
`;

const HeaderSection = ({ images: { main, mobile } }) => {
    if (!main || !mobile) {
        return null;
    }

    const desktopImage = getOriginalSizePNG(main);
    const mobileImage = getOriginalSizePNG(mobile);

    return (
        <HeaderWrapper>
            <img className="desktop" src={desktopImage} alt="Header" />
            <img className="mobile" src={mobileImage} alt="Header" />
        </HeaderWrapper>
    );
};
export default HeaderSection;
