import React,{useContext} from "react";
import styled from "styled-components";
import { flexCenter, flexColumn } from "../mixins";
import SEO from "../SEO/SEO.component.js";
import {webinarContext} from "../../providers/webinarTemplateProvider/webinar.provider";
import NotFoundComponent from "../../components/404/404.component"

const StreamWrapper = styled.div`
    ${flexCenter};
    width: 100%;
    height: calc(100vh - 80px);

    @media screen and (max-width: 1080px) {
        ${flexColumn};
        height: auto;
    }
`;

const StreamBox = styled.iframe`
    width: 70%;
    height: 100%;
    border: none;

    @media screen and (max-width: 1080px) {
        width: 100%;
        min-height: 400px;
        margin: 20px 0;
    }

    @media screen and (max-width: 560px) {
        width: 100%;
        min-height: 300px;
        margin: 20px 0;
    }

    @media screen and (max-width: 400px) {
        width: 100%;
        min-height: 200px;
        margin: 20px 0;
    }
`;

const ChatBox = styled.iframe`
    width: 30%;
    height: 100%;
    border: none;
    border-left: 1px solid ${({ theme }) => theme.colors.black};

    @media screen and (max-width: 1080px) {
        width: 100%;
        min-height: 450px;
        border: none;
    }
`;

const StreamComponent = () => {

    const {data:{vimeo_stream_number,is_live}} = useContext(webinarContext);

    if(!is_live){
        return <NotFoundComponent message="Nie odnaleziono podanego źródła."/>
    }

    return (
        <SEO title="Webinar - LIVE" description="Strona Live webinaru">
            {vimeo_stream_number ? 
            (
            <>
                <StreamWrapper>
                <StreamBox
                    src={`https://vimeo.com/event/${vimeo_stream_number}/embed`} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                    width="1920"
                    height="1080"
                />
                <ChatBox src={`https://vimeo.com/event/${vimeo_stream_number}/chat/interaction/`} />
            </StreamWrapper>
            </>): <NotFoundComponent message="Nie odnaleziono źródła."/>}
        </SEO>
    );
}
export default StreamComponent;
