import React from "react"
import { Switch, Route } from "react-router-dom"
import MainPage from "./MainPage"
import StreamPage from "../StreamPage/Stream.page"
import NotFoundComponent from "../../components/404/404.component"
import VideoPage from "../VideoPage/Video.page"

const MainPageRouter = ({ match }) => {
    return (
        <>
            <Switch>
                <Switch>
                    <Route path={`${match.path}:slug`} component={MainPage} />
                    <Route
                        exact
                        path={`${match.path}`}
                        component={NotFoundComponent}
                    />
                    {/*<Route
                        exact
                        path={`${match.path}:slug/stream`}
                        component={StreamPage}
                    />
                    <Route
                        exact
                        path={`${match.path}:slug/nagranie`}
                        component={VideoPage}
                    />*/}
                </Switch>
            </Switch>
        </>
    )
}
export default MainPageRouter
