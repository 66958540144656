import React, { useContext, useLayoutEffect, useCallback } from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import SpinnerComponent from "../../components/Spinner/Spinner.component"
import { webinarContext } from "../../providers/webinarTemplateProvider/webinar.provider"
import NotFoundComponent from "../../components/404/404.component"
import MainPageTemplate from "./MainPage.template"
import StreamComponent from "../../components/StreamComponent/Stream.component"
import VideoComponent from "../../components/VideoComponent/Video.component"
import ReactPixel from "react-facebook-pixel"

const MainPageRoot = ({ match }) => {
    const {
        params: { slug },
        path,
        url,
    } = match

    const { data, fetchData, loading, error } = useContext(webinarContext)

    useLayoutEffect(() => {
        fetchData(slug)
    }, [slug])

    useLayoutEffect(() => {
        if (!loading) {
            const initializeFacebookPixel = (pixelId) => {
                const options = {
                    autoConfig: true,
                    debug: false,
                }

                ReactPixel.init(pixelId, null, options)

                ReactPixel.pageView() // For tracking page view
            }
            if (data) {
                initializeFacebookPixel(data.domain.facebook_pixel_id)
            }
        }
    }, [loading, data])

    if (error) {
        return <NotFoundComponent />
    } else if (loading) {
        return <SpinnerComponent />
    }

    return (
        <Switch>
            <Route path={`${path}`} exact component={MainPageTemplate} />
            <Route path={`${path}/stream`} component={StreamComponent} />
            <Route path={`${path}/nagranie`} component={VideoComponent} />
            <Redirect from={`${path}/*`} to={`${path}`} />
        </Switch>
    )
}

export default MainPageRoot
