import React from "react";
import styled, { css } from "styled-components";
import RainPNG from "../../assets/img/deszcz.png";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle.component";
import { flexCenter, flexColumn } from "../../components/mixins";
import Button from "../../components/Button/Button.component";
import ResponsiveImage from "../../components/ResponsiveImage/ResponsiveImg.component";
import { scrollTo } from "../../utils/scrollTo";
import { getAllSizesPNG } from "../../utils/imageUtils";
import ReactMarkdown from "react-markdown";

const SectionWrapper = styled.section`
    ${flexCenter};
    ${flexColumn};
    padding: 50px 30px;
    width: 100%;
    color: ${({ theme }) => theme.colors.grey};

    ${({ inverted }) =>
        inverted
            ? css`
                  background-color: ${({ theme }) => theme.colors.white};
              `
            : ""}
`;

const ProgramWrapper = styled.div`
    ${flexCenter};
    justify-content: space-between;
    color: ${({ theme }) => theme.colors.dark_grey};
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 30px;

    @media screen and (max-width: 1000px) {
        ${flexColumn};
    }
`;

const LeftBox = styled.div`
    margin-right: 100px;

    & > picture {
        width: 280px;
        height: 280px;
        margin: 15px auto;

        img {
            width: 280px;
            height: 280px;
            object-fit: cover;
            border-radius: 50%;
            border: 1px solid ${({ theme }) => theme.colors.black};
        }

        @media screen and (max-width: 560px) {
            width: 180px;
            height: 180px;

            img {
                width: 180px;
                height: 180px;
            }
        }
    }

    @media screen and (max-width: 1000px) {
        margin-right: 0;
    }
`;

const RightBox = styled.div`
    p,
    ul,
    ol {
        font-size: ${({ theme }) => theme.fz.S};
        font-weight: ${({ theme }) => theme.fw.light};

        @media screen and (max-width: 560px) {
            font-size: ${({ theme }) => theme.fz.XS};
        }
    }

    ul,
    ol {
        padding-left: 40px;

        @media screen and (max-width: 560px) {
            text-align: left;
            padding-left: 20px;
        }
    }

    ul {
        list-style: none;
        list-style-type: none;
    }

    ol li,
    ul li {
        position: relative;
        margin: 20px 0;

        ul,
        ol {
            padding-left: 20px;
        }
    }

    ul li {
        &::before {
            position: absolute;
            top: 0.4em;
            left: 0;
            transform: translateX(-1.2em);
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: ${({ theme }) => theme.colors.dark_grey};
        }
    }
`;

const ProgramSection = ({ programImg, content }) => {
    const programIMGsource = !!programImg && getAllSizesPNG(programImg);

    return (
        <SectionWrapper id="program">
            <HeaderTitle>
                <h2>Co w programie</h2>
            </HeaderTitle>

            <ProgramWrapper>
                {programIMGsource && (
                    <LeftBox>
                        <ResponsiveImage
                            medium={programIMGsource.medium}
                            small={programIMGsource.small}
                            large={programIMGsource.large}
                            alt="Zdjęcie programu webinaru"
                        />
                    </LeftBox>
                )}
                <RightBox>
                    <ReactMarkdown>{content}</ReactMarkdown>
                </RightBox>
            </ProgramWrapper>
            <Button onClick={scrollTo("#formularz")}>ZAPISZ SIĘ</Button>
        </SectionWrapper>
    );
};
export default ProgramSection;
