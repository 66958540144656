export const pngSource = (pathFile) => {
    if (!pathFile) return undefined;

    return `https://strapi.semantika.pl${pathFile}`;
};

export const getPNG = (imgObj, size = "medium") =>{
    if(imgObj?.formats?.[size.toLowerCase()]){
        return pngSource(imgObj?.formats?.[size.toLowerCase()]?.url) || undefined;
    }else {
        return pngSource(imgObj?.url) || undefined;
    }
}

export const getOriginalSizePNG = (imgObj) => pngSource(imgObj?.url);

export const getAllSizesPNG = (imgObj) => ({
    thumbnail: getPNG(imgObj, "thumbnail"),
    large: getPNG(imgObj, "large"),
    small: getPNG(imgObj, "small"),
    medium: getPNG(imgObj, "medium"),
});
