import React, { useContext } from "react"
import SEO from "../../components/SEO/SEO.component"
import HeaderSection from "../../sections/Header/Header.section"
import EventDateSection from "../../sections/EventDate/EventDate.section"
import { convertToLocalDate } from "../../utils/dateUtils"
import PatronsSection from "../../sections/PatronsSection/Patrons.section"
import logo_SPBKD from "../../assets/img/logo_spbkd.png"
import logo_SPC from "../../assets/img/logo_spc.png"
import logo_Haba from "../../assets/img/logo_haba.jpg"
import logo_Elkon from "../../assets/img/logo_elkon.png"
import partnerIsprzetJPG from "../../assets/img/partner2.jpg"
import SectionsComponent from "../../sections/Sections/Sections.component"
import ProgramSection from "../../sections/ProgramSection/Program.section"
import SpeakerSection from "../../sections/SpeakerSection/Speaker.section"
import FormSection from "../../sections/FormSection/Form.section"
import { webinarContext } from "../../providers/webinarTemplateProvider/webinar.provider"
import NotFoundComponent from "../../components/404/404.component"
import SpinnerComponent from "../../components/Spinner/Spinner.component"

const TemplatePage = () => {
    console.log("IM RENDERED")
    //Template context
    const templateContext = useContext(webinarContext)

    if (templateContext.loading) {
        return <SpinnerComponent />
    }

    if (!templateContext.data) {
        return <NotFoundComponent />
    } else if (templateContext.data) {
        //Create refs from context

        const {
            title,
            description,
            slug,
            date,
            time,
            section_1,
            section_2,
            program,
            program_image,
            is_form_active,
            speaker,
            main_image,
            mobile_image,
            redlink_list_name,
        } = templateContext.data

        //If specific slug and domain are resolved - render patrons section
        const domain =
            process.env.NODE_ENV === "production"
                ? window?.location?.host
                : "live.drogigminneipowiatowe.pl"

        const showPatronsSection =
            domain === "live.drogigminneipowiatowe.pl" &&
            slug ===
                "nowe-technologie-w-projektowaniu-nawierzchni-z-wykorzystaniem-kostki-brukowej"

        const showPatronsSectionTwo =
            domain === "live.drogigminneipowiatowe.pl" &&
            slug === "samorzadowe-drogi-betonowe"
        return (
            <SEO title={`Webinar: ${title}`} description={description}>
                <HeaderSection
                    images={{ main: main_image, mobile: mobile_image }}
                />
                <EventDateSection
                    date={convertToLocalDate({ date, time })}
                    eventTitle={title}
                />
                {showPatronsSection && (
                    
                        <PatronsSection
                            patrons={[
                                {
                                    img: logo_SPBKD,
                                    url: "https://kostka-brukowa.org.pl/",
                                    description: null,
                                    id: 1,
                                },
                                {
                                    img: partnerIsprzetJPG,
                                    url: "https://www.isprzet.pl",
                                    id: 2,
                                },
                            ]}
                            title={"Partnerzy wydarzenia"}
                            isRow={true}
                        />
                    
                )}
                {showPatronsSectionTwo && (
                    <PatronsSection
                        patrons={[
                            {
                                img: logo_SPC,
                                url: "https://www.polskicement.pl/",
                                id: 3,
                            },
                        ]}
                    />
                )}
                <SectionsComponent
                    sections={{
                        section1: section_1,
                        section2: section_2,
                    }}
                    eventDescription={description}
                />
                <ProgramSection programImg={program_image} content={program} />
                <SpeakerSection
                    name={speaker?.name}
                    title={speaker?.title}
                    bio={speaker?.bio}
                    imageFormats={speaker?.image}
                />
                {/* {showPatronsSection && (
                    <PatronsSection
                        patrons={[
                            {
                                img: partnerIsprzetJPG,
                                url: "https://www.isprzet.pl",
                                id: 2,
                            },
                        ]}
                        title="Partnerzy wydarzenia"
                    />
                )} */}

                {showPatronsSectionTwo && (
                    <PatronsSection
                        patrons={[
                            {
                                img: logo_Elkon,
                                url: "https://www.concretebatchingplants.com/pl",
                                id: 4,
                            },
                            {
                                img: logo_Haba,
                                url: "https://www.haba-beton.com/pl/",
                                id: 5,
                            },
                        ]}
                        title="Partnerzy wydarzenia"
                        isRow={true}
                    />
                )}
                <FormSection
                    isFormActive={is_form_active}
                    eventDate={convertToLocalDate({ date, time })}
                    groupSlug={slug}
                    groupName={redlink_list_name}
                    webinarName={title}
                />
            </SEO>
        )
    }

    return null
}

export default TemplatePage
