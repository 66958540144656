import React, { useState } from "react"
import styled, { css } from "styled-components"
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle.component"
import { flexCenter, flexColumn } from "../../components/mixins"
import Button from "../../components/Button/Button.component"
import ReactPixel from "react-facebook-pixel"

const FormWrapper = styled.div`
    width: 100%;
    padding: 50px 30px;
    background-color: ${({ theme }) => theme.colors.light_grey};
    text-align: center;
`

const Form = styled.form`
    ${flexCenter};
    ${flexColumn};
    flex-wrap: wrap;
    width: 100%;
    max-width: 900px;
    margin: 0px auto 20px;

    input {
        margin: 10px;
        padding: 5px 10px;
        min-width: 350px;
        border: none;
        font-family: ${({ theme }) => theme.ff.Roboto};
        font-size: ${({ theme }) => theme.fz.XS};
        color: ${({ theme }) => theme.colors.black};

        &::placeholder {
            font-family: ${({ theme }) => theme.ff.Roboto};
            font-size: ${({ theme }) => theme.fz.XS};
            color: ${({ theme }) => theme.colors.black};
        }
    }

    @media screen and (max-width: 560px) {
        input {
            min-width: unset;
            width: 100%;
            margin: 10px auto;
        }
    }
`

const OrderTitle = styled.h6`
    margin: 50px 0 0;
    font-weight: ${({ theme }) => theme.fw.regular};
`

const Message = styled.h6`
    margin: 15px auto;
    color: ${({ theme }) => theme.colors.green};

    ${({ variant }) =>
        variant === "error"
            ? css`
                  color: ${({ theme }) => theme.colors.red};
              `
            : ""}
`

const P = styled.p`
    margin: 10px auto 0;
    font-size: ${({ theme }) => theme.fz.S};
    color: ${({ theme }) => theme.colors.grey};

    ${({ big }) =>
        big
            ? css`
                  font-size: ${({ theme }) => theme.fz.L};
              `
            : ""}

    ${({ thick }) =>
        thick
            ? css`
                  font-weight: ${({ theme }) => theme.fw.bold};
                  color: ${({ theme }) => theme.colors.dark_grey};
              `
            : ""}
            
    ${({ flex }) =>
        flex
            ? css`
                  ${flexCenter};
              `
            : ""}

    ${({ short }) =>
        short
            ? css`
                  max-width: 1000px;
                  margin-left: auto;
                  margin-right: auto;
              `
            : ""}
            
            ${({ light, theme }) =>
        light
            ? css`
                  font-weight: ${theme.fw.light};
              `
            : ""}

            i {
        font-size: 0.7em;
    }
`

const EventDate = styled.h4`
    margin-top: 15px;
    font-weight: ${({ theme }) => theme.fw.regular};
`

const FormSection = ({
    isFormActive = false,
    eventDate,
    groupSlug,
    groupName,
    webinarName,
}) => {
    const [errorPhone, setErrorPhone] = useState(null)
    const [responseResult, setResponseResult] = useState(null)
    const [prohibitSending, setProhibition] = useState(false)

    const [data, setData] = useState({
        name: "",
        surname: "",
        email: "",
        phone: "",
    })

    if (!isFormActive || !groupName) {
        return (
            <FormWrapper id="formularz">
                <HeaderTitle>
                    <h2>Formularz zapisu</h2>
                </HeaderTitle>
                <h4>Brak możliwości zapisów.</h4>
            </FormWrapper>
        )
    }

    const handleChange = (e) => {
        const prop = e.currentTarget.name
        const value = e.currentTarget.value

        setData((prevData) => ({ ...prevData, [prop]: value }))
    }

    const validate = (input) => {
        if (input.includes("+")) {
            return Error(
                "Poprawny format numeru telefonu: XXXXXXXXX. (Bez numeru kierunkowego +48)"
            )
        }

        const number = +input
        if (typeof number !== "number" || number.toString().length !== 9) {
            return Error(
                "Format numeru telefonu: XXXXXXXXX. Numer nie powinien zaczynać się od zera."
            )
        }

        return true
    }

    const clearErrors = () => {
        setErrorPhone(null)
        setResponseResult(null)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        clearErrors()
        setProhibition(true)

        const phoneError = validate(data.phone)

        if (phoneError instanceof Error) {
            setErrorPhone(phoneError)
            setProhibition(false)
            return
        }

        try {
            const { name, surname, email, phone } = data

            const body = JSON.stringify({
                firstname: name,
                lastname: surname,
                email,
                phonenumber: phone,
                slug: groupSlug,
                groupName,
            })

            const res = await fetch(
                "https://api.semantika.pl/api/webinars/registration",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body,
                }
            )

            console.log("RESPONSE FROM FORM: ", res)

            if (res.ok) {
                setResponseResult({
                    status: res.status,
                    statusText: res.statusText,
                })
                //Perhaps use ReactPixel.trackSingle
                ReactPixel.track("Purchase", {
                    content_name: webinarName,
                })
            } else {
                setResponseResult({
                    status: res.status,
                    statusText: res.statusText,
                })
            }

            if (res.status != "201") {
                setProhibition(false)
            }
        } catch (e) {
            console.log(e, "ERROR FROM FORM")

            setResponseResult({
                status: 500,
                statusText: "Coś poszło nie tak. Spróbuj ponownie później.",
            })
        }
    }

    return (
        <FormWrapper id="formularz">
            <HeaderTitle>
                <h2>Formularz zapisu</h2>
            </HeaderTitle>

            <P>SZKOLENIE ONLINE</P>
            <P flex big thick>
                <i className="fa fa-certificate" aria-hidden="true"></i>
                &nbsp;BEZPŁATNE&nbsp;
                <i className="fa fa-certificate" aria-hidden="true"></i>
            </P>

            {eventDate && <EventDate>{eventDate}</EventDate>}

            <OrderTitle>Dane uczestnika</OrderTitle>
            <Form onSubmit={handleSubmit}>
                <div>
                    <input
                        required
                        type="text"
                        name="name"
                        placeholder="Imię"
                        onChange={handleChange}
                    />
                    <input
                        required
                        type="text"
                        name="surname"
                        placeholder="Nazwisko"
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <input
                        required
                        type="email"
                        name="email"
                        placeholder="Adres e-mail"
                        onChange={handleChange}
                    />
                    <input
                        required
                        type="text"
                        minLength="9"
                        maxLength="9"
                        name="phone"
                        placeholder="Numer telefonu"
                        onChange={handleChange}
                    />
                </div>

                {errorPhone && (
                    <Message variant="error">{errorPhone.message}</Message>
                )}
                {responseResult && (
                    <Message
                        variant={responseResult.status == "201" ? "" : "error"}
                    >
                        {responseResult.statusText}
                    </Message>
                )}

                <Button type="submit" disabled={prohibitSending}>
                    Zapisz się
                </Button>
            </Form>
        </FormWrapper>
    )
}
export default FormSection
