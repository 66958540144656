import styled, { css } from "styled-components";

const flexCenter = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const flexColumn = css`
    display: flex;
    flex-direction: column;
`;

const rgbaWhite = (alpha) => `rgba(255,255,255,${alpha})`;

const transitionMixin = (time = "0.3s") => css`
    transition: ${time};
`;

const marginToChildren = (margin = "0 0 15px") => css`
    & > * {
        margin: ${margin};
    }
`;

export { flexCenter, flexColumn, transitionMixin, marginToChildren, rgbaWhite };
