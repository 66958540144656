import React, { useContext } from "react";
import GlobalStyle from "./GlobalStyle";
import styled from "styled-components";
import { NavigationMenuFloating } from "./Navigation";
import Footer from "./Footer";
import SEO from "./../components/SEO/SEO.component";
import { webinarContext } from "../providers/webinarTemplateProvider/webinar.provider";
import { getOriginalSizePNG } from "../utils/imageUtils";

const Container = styled.div`
    position: relative;
    width: 70%;
    max-width: 1170px;
    min-width: 1170px;
    min-height: 80vh;
    margin: 150px auto 100px;
    padding: 50px 80px 100px;
    background-color: ${({ theme }) => theme.colors.white};

    @media screen and (max-width: 1250px) {
        min-width: 960px;
    }

    @media screen and (max-width: 1050px) {
        min-width: 750px;
        padding: 100px 80px;
    }

    @media screen and (max-width: 830px) {
        min-width: unset;
        padding: 80px 0px;
        margin: 0px auto;
        width: 100%;
    }
`;

const LayoutComponent = React.forwardRef(({ children }, ref) => {
    const webinarTemplate = useContext(webinarContext);

    const webinarLogo =
        webinarTemplate.loading === false &&
        getOriginalSizePNG(webinarTemplate.data.domain.logo);

    return (
        <SEO title="Nazwa Webinaru" description="Strona webinaru.">
            <GlobalStyle />

            <NavigationMenuFloating logo={webinarLogo ? webinarLogo : null} />
            {children}

            <Footer />
        </SEO>
    );
});

export default LayoutComponent;
