import React from "react";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle.component";
import SectionTemplate from "../../components/SectionTemplate/Section.template";
import ReactMarkdown from "react-markdown";

const SectionsComponent = ({
    sections: { section1, section2 },
    eventDescription,
}) => (
    <>
        <SectionTemplate>
            <HeaderTitle>
                <h2>Dla kogo</h2>
            </HeaderTitle>
            <ReactMarkdown>{eventDescription}</ReactMarkdown>
        </SectionTemplate>

        {section1 && (
            <SectionTemplate inverted>
                <HeaderTitle>
                    <h2>Czego dowiesz się na szkoleniu</h2>
                </HeaderTitle>
                <ReactMarkdown>{section1}</ReactMarkdown>
            </SectionTemplate>
        )}

        {section2 && (
            <SectionTemplate>
                <HeaderTitle>
                    <h2>Czego dowiesz się na szkoleniu</h2>
                </HeaderTitle>
                <ReactMarkdown>{section2}</ReactMarkdown>
            </SectionTemplate>
        )}
    </>
);
export default SectionsComponent;
