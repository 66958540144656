import React, { createContext, useEffect, useState } from "react"

const data = {
    title: "",
    subtitle: "",
    slug: "",
    date: "",
    time: "",
    description: "",
    section_1: "",
    section_2: "",
    program: "",
    is_form_active: false,
    is_live: false,
    is_materials: false,
    vimeo_stream_number: null,
    vimeo_video_number: null,
    materials_link: null,
    redlink_list_name: "",
    partner_logo: null,
    partner_description: null,
    domain: {
        id: null,
        name: "",
        facebook_pixel_id: null,
        logo: {
            formats: null,
        },
        url: "",
    },
    speaker: {
        id: null,
        name: "",
        bio: "",
        title: "",
        image: {
            formats: null,
        },
    },
    main_image: "",
    mobile_image: "",
    program_image: "",
}

const initialContextState = {
    data,
    setData: () => {},
    fetchData: () => {},
    loading: false,
    error: null,
}

export const webinarContext = createContext(initialContextState)

const WebinarTemplateProvider = ({ children }) => {
    const [templateState, setTemplateState] = useState(data)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    function setData(data) {
        setTemplateState((prev) => ({
            ...prev,
            ...data,
        }))
    }

    async function fetchData(slug) {
        setLoading(true)

        const currentDomain =
            process.env.NODE_ENV === "production"
                ? window?.location?.host
                : "webinary.wspolczesnadietetyka.pl"

        const response = await fetch(
            `https://strapi.semantika.pl/webinars?domain.name=${currentDomain}&slug=${slug}`
        )

        if (!response.ok) {
            setError(new Error("Coś poszło nie tak. Źródło nieodnalezione."))
            setLoading(false)
            return
        }

        const data = await response.json()

        if (data && data.length && data?.[0]) {
            setLoading(false)
            setTemplateState(data[0])
        } else {
            setLoading(false)
            setError(
                new Error(`Nie można znaleźć podanego webinaru - slug: ${slug}`)
            )
        }
    }

    return (
        <webinarContext.Provider
            value={{ data: templateState, setData, fetchData, loading, error }}
        >
            {children}
        </webinarContext.Provider>
    )
}
export default WebinarTemplateProvider
